import { useEffect, useState } from "react";
import Chart from "../charts/Chart";
import api from "../../services/api";
import { ImCalendar } from "react-icons/im";
import { CgScreen } from "react-icons/cg";
import { DataGrid } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { RiUserVoiceLine, RiTimer2Line } from "react-icons/ri";
import { Column, Paging, SearchPanel } from "devextreme-react/data-grid";

import "./Main.css";

const Main = () => {
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [monthlyAppointments, setMothyAppointments] = useState(0);
  const [averageServiceTime, setAverageServiceTime] = useState(0.0);
  const [qtdTotems, setQtdTotems] = useState(0);
  const [dataSourceGraph, setDataSourceGraph] = useState(null);
  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    api
      .get("dashboard/card")
      .then((response) => {
        setTotalAppointments(response.data.totalAppointments);
        setMothyAppointments(response.data.monthlyAppointments);
        setAverageServiceTime(
          `${parseFloat(
            response.data.averageServiceTime[0].avgDuration / 60
          ).toFixed(2)}/h`
        );
        setQtdTotems(response.data.qtdTotems);
      })
      .catch((err) => {
        console.log(err);
        notify({
          message: "Problema com a conexão com o servidor",
          type: "error",
          displayTime: 3000,
          // height: 100,
        });
      });
    api
      .get("dashboard/graph")
      .then((response) => {
        setDataSourceGraph(response.data);
      })
      .catch((err) => {
        notify({
          message: `${err}`,
          type: "error",
          displayTime: 3000,
          // height: 100,
        });
      });
    api
      .get("/dashboard/report")
      .then((response) => {
        setDataSource(response.data[0]);
      })
      .catch((err) => {
        notify({
          message: `${err}`,
          type: "error",
          displayTime: 2000,
        });
      });
  }, []);

  return (
    <main>
      <div className="main__container">
        {/* <div className="main__title">
          <div className="main__greeting">
            <h1>Olá</h1>
            <p> Bem vindo ao seu painel</p>
          </div>
        </div> */}

        <div className="main__cards">
          <div className="card">
            <RiUserVoiceLine size="32" />
            <div className="card_inner">
              <p className="text-primary-p">Total de atendimentos</p>
              <span className="font-bold text-title">{totalAppointments}</span>
            </div>
          </div>

          <div className="card">
            <ImCalendar size="32" />
            <div className="card_inner">
              <p className="text-primary-p">Atendimentos do mês</p>
              <span className="font-bold text-title">
                {monthlyAppointments}
              </span>
            </div>
          </div>

          <div className="card">
            <RiTimer2Line size="32" />
            <div className="card_inner">
              <p className="text-primary-p">Tempo médio de atendimento</p>
              <span className="font-bold text-title">{averageServiceTime}</span>
            </div>
          </div>

          <div className="card">
            <CgScreen size="32" />
            <div className="card_inner">
              <p className="text-primary-p">Qtd De Totens</p>
              <span className="font-bold text-title">{qtdTotems}</span>
            </div>
          </div>
        </div>

        <div className="charts">
          <div className="charts__left">
            <div className="charts__left__title">
              <div>
                <h1>Gráfico Mensal</h1>
                <p>Natal, RN</p>
              </div>
              {/* <i className="fa fa-usd"></i> */}
            </div>
            <Chart dataSource={dataSourceGraph} />
          </div>

          <div className="charts__right">
            <div className="charts__right__title">
              <div>
                <h1>Relatório</h1>
                <p>Natal, RN</p>
              </div>
              {/* <i className="fa fa-area-chart"></i> */}
            </div>
            <DataGrid
              allowColumnReordering={true}
              dataSource={dataSource}
              key="id"
            >
              <Column
                dataField="id"
                caption={"ID"}
                dataType="number"
                alignment={"left"}
              />
              <Column
                alignment={"center"}
                dataField="duration"
                caption={"Duração"}
                dataType="number"
              ></Column>
              <Column
                dataField="clerk_name"
                caption={"Atendente"}
                dataType="string"
                alignment={"center"}
              />
              <Column
                dataField="name"
                caption={"Totem"}
                dataType="string"
                alignment={"center"}
              />
              <Column
                dataField="created_at"
                alignment={"right"}
                caption={"Data"}
                dataType="date"
              />
              <SearchPanel visible={true} />
              <Paging defaultPageSize={10} defaultPageIndex={0} />
            </DataGrid>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Main;
