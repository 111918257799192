import Chart, { Legend, Series, Tooltip } from "devextreme-react/chart";
import { useEffect } from "react";

export const continentSources = [
  { value: "sumDuration", name: "Tempo Chamada" },
  { value: "atdTotem", name: "Quantidade Atendimento" },
];

const Graphic = ({ dataSource }) => {
  useEffect(() => {
    console.log("Chegou");
    console.log(dataSource);
  }, [dataSource]);
  return (
    <Chart id="chart" dataSource={dataSource}>
      <Series
        valueField="sumDuration"
        argumentField="id_totem"
        name="Duração de Atendimento"
        type="bar"
        color="#006494"
      />
      <Legend verticalAlignment="bottom" horizontalAlignment="center" />
      <Tooltip
        enabled={true}
        shared={true}
        customizeTooltip={customizeTooltip}
      />
    </Chart>
  );
};

function customizeTooltip(pointInfo) {
  const items = pointInfo.valueText.split("\n");
  const color = pointInfo.point.getColor();

  items.forEach((item, index) => {
    if (item.indexOf(pointInfo.seriesName) === 0) {
      const element = document.createElement("span");

      element.textContent = item;
      element.style.color = color;
      element.className = "active";

      items[index] = element.outerHTML;
    }
  });

  return { text: items.join("\n") };
}

export default Graphic;
