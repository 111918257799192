import { DataGrid } from "devextreme-react";
import { Column, Paging, SearchPanel } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { useEffect, useState } from "react";

import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import api from "../../services/api";

import './index.css';

const TypeCall = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [permissions, setPermissions] = useState();

  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    let arr = JSON.parse(localStorage.getItem("FLY@User"));
    if (arr) {
      setPermissions(arr);
    }

    const getData = async () => {
      let resp = await api.get("/calltype");
      console.log(resp.data);
      if (resp.status === 200) {
        setDataSource(resp.data);
      }
    };

    getData();
  }, []);
  const openSidebar = () => {
    setSidebarOpen(true);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    let data = {
      type: input1,
      id_company: permissions.user.id_company,
    };
    
    if(input1 == "") {
      notify({
        message: "Atenção, campo não pode ficar vazio",
        type: "warning",
        delayTime: 1500,
        width: 300,
        shading: false,
        animation: {
          hide: { type: "fade", duration: 40, to: 0 },
          show: { type: "fade", duration: 400, to: 1, from: 0 },
        },
      });
      return;
    }
    const resp = await api.post("/calltype", data);

    if (resp.status === 200) {
      window.location.reload();
    }
  };

  return (
    <div className="container">
      <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />

      <main>
        <div className="main__container">
          <h1>Cadastro de Tipo de Chamados</h1>
          <hr />
          <br />
          <div>
            <form onSubmit={handlerSubmit}>
              <input
                type="text"
                name={input1}
                id="input1"
                placeholder="Tipo de chamado..."
                onChange={(e) => setInput1(e.target.value)}
              />
              <br />
              <button type="submit"> Cadastrar</button>
            </form>
          </div>
          <br />
          <hr />
          <br />
          <div className="table_type_call">
            <DataGrid
              allowColumnReordering={true}
              dataSource={dataSource}
              key="id"
            >
              <Column
                width={55}
                dataField="id"
                caption={"ID"}
                dataType="number"
                alignment={"left"}
              />
              <Column
                dataField="type"
                caption={"Motivo da Chamada"}
                dataType="string"
                alignment={"left"}
              />
              <Column
                width={250}
                dataField="createdAt"
                alignment={"center"}
                caption={"Cadastro"}
                dataType="datetime"
              />
              <SearchPanel visible={true} />
              <Paging defaultPageSize={10} defaultPageIndex={0} />
            </DataGrid>
          </div>
        </div>
      </main>
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );
};

export default TypeCall;
