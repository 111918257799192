import notify from "devextreme/ui/notify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import api from "../../services/api";



import './index.css';

const CallEnd = () => {

    const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [permissions, setPermissions] = useState();

  const [date2, setDate2] = useState();

  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    let arr = JSON.parse(localStorage.getItem("FLY@User"));
    if (arr) {
      setPermissions(arr);
    }

    const arr2 = async () => {
        let resp = await api.get("/calltype");
        if(resp.status === 200){
            setDate2(resp.data)
        }
    }
    arr2();

  }, []);
  const openSidebar = () => {
    setSidebarOpen(true);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();

    let ddd = localStorage.getItem("@FLY:room_id");
    let data = {
      _id_clerck: permissions.clerk.id,
      room_description: input1,
      room_id:ddd
    };
    if(input1 === "") {
      notify({
        message: "Atenção, campo não pode ficar vazio",
        type: "warning",
        delayTime: 1500,
        width: 300,
        shading: false,
        animation: {
          hide: { type: "fade", duration: 40, to: 0 },
          show: { type: "fade", duration: 400, to: 1, from: 0 },
        },
      });
      return;
    }
    const resp = api.post("/rooms/finish", data);

     navigate("/dashboard");
  };

  return (
    <div className="container">
      <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />

      <main>
        <div className="main__container">
          <h1>Descrição do Atendimento</h1>
          <hr />
          <br />
          <div>
            <form  className="form-user" onSubmit={handlerSubmit}>
            <div className="div-single">
              <input
                type="text"
                name={input1}
                id="input1"
                placeholder="Descrição da chamada"
                onChange={(e) => setInput1(e.target.value)}
              />
              </div>
              <br />
              <div className="div-single">
                  <label>Ativo</label>
                  <select name={input2} id="input2" onChange={e => setInput2(e.target.value)}>
                    <option value="">.:Selecione um opção:.</option>
                    { date2 && date2.map(item => (
                        <option key={item.id} value={item.id}> {item.type}</option>
                    ))}
                  </select>
                </div>
                <br/>
              <button type="submit"> Cadastrar</button>
            </form>
          </div>
          <br />
          <hr />
          <br />
        </div>
      </main>
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );
};

export default CallEnd;
