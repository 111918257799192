import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div>
        <h1>Não foi possível encontrar essa página!</h1>
      </div>
      <hr />
      <div>
        <button onClick={goBack}>Volta</button>
        <button
          onClick={() => {
            navigate("dashboard");
          }}
        >
          Página inicial
        </button>
      </div>
    </>
  );
};

export default NotFound;
