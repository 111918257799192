import { useContext, useEffect, useState } from "react";
import { DataGrid } from "devextreme-react/data-grid";

import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import api from "../../../services/api";

import { SocketContext } from "../../../Context";

import "./index.css";
import { useNavigate } from "react-router";

const PainelClerk = () => {
  const navigate = useNavigate();

  const { socket } = useContext(SocketContext);
  const [resp, setResp] = useState(null);
  const [haveCall, setHaveCall] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dataSource, setDataSource] = useState(null);

  const [userLocal, setUserLocal] = useState();

  useEffect(() => {
    const getDataApi = async () => {
      const aa = await JSON.parse(localStorage.getItem("FLY@User"));
      setUserLocal(aa);
      const resp = await api.get(`/rooms/my/${aa.clerk.id}`);
      if (resp.status === 200) {
        setDataSource(resp.data);
      }
    };
    getDataApi();
  }, []);

  socket.on("tem_chamada", async (resp) => {
    if (userLocal != null) {
      if (resp.room.id_clerk === userLocal.clerk.id) {
        setResp(resp);
        setHaveCall(true);
      }
    }
  });
  const openSidebar = () => {
    setSidebarOpen(true);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  const answeRCall = () => {
    document.cookie = `idc=${userLocal.clerk.id}`;
    window.open(`https://flytoten.corpstek.com.br/join/${resp.room.id}`);
    setHaveCall(false);
  };

  return (
    <div className="container">
      <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      <main>
        <div className="main__container">
          <div className="main__title">
            <div className="main__greeting">
              <p>Bem vindo a área do Atendente</p>
            </div>
          </div>
          <br />
          {haveCall && (
            <div className="area__call">
              <div
                className="calling"
                onClick={() => {
                  answeRCall();
                }}
              >
                <h4>Chamando...</h4>
              </div>
            </div>
          )}
          <div className="reports-area">
            <DataGrid id="dataGrid" dataSource={dataSource}></DataGrid>
          </div>
        </div>
      </main>
      <Sidebar sidebarOpen={sidebarOpen} key="id" closeSidebar={closeSidebar} />
    </div>
  );
};
export default PainelClerk;
