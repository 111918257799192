import axios from "axios";

const api = axios.create({
  // baseURL: "http://192.168.88.96:5555",
  baseURL: "https://apiflytoten.corpstek.com.br",
});

api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default api;
