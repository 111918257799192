import logo from "../../assets/avatar.jpg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import "./Sidebar.css";

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  const navigate = useNavigate();

  const [dataPermissions, setDataPermissionUser] = useState();
  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem("FLY@User")));
    setDataPermissionUser(JSON.parse(localStorage.getItem("FLY@User")));
    const arr = JSON.parse(localStorage.getItem("FLY@User"));
    if (!arr) {
      navigate("/");
    } else {
      setUserInfo(arr.user.name);
    }
  }, []);

  const logoff = () => {
    localStorage.clear();
    navigate("/");
  };

  const dashboard = () => {
    navigate("/dashboard");
  };

  const painel = () => {
    navigate("/dashboard");
  };
  const areaClerk = () => {
    navigate("/dashboard");
  };

  const areaInterprete = () => {
    navigate("/dashboard");
  };

  const usersRouter = () => {
    navigate("/users");
  };
  const TypeRouter = () => {
    navigate("/types");
  };

  return (
    <div className={sidebarOpen ? "sidebar-responsive" : ""} id="sidebar">
      <div>
        <div className="sidebar__title">
          <div className="sidebar__img">
            <img src={logo} alt="logo" />
            <h1>{userInfo}</h1>
          </div>
          <i
            onClick={() => closeSidebar()}
            className="fa fa-times"
            id="sidebarIcon"
            aria-hidden="true"
          ></i>
        </div>
        <div className="sidebar__menu">
          {/* <div className="sidebar__link" onClick={() => dashboard()}>
            <i className="fa fa-minus-square"></i>
            <span>Home</span>
          </div> */}
          <h2>PAINEL</h2>
          {dataPermissions?.user.user_type === 4 && (
            <div className="sidebar__link" onClick={() => painel()}>
              <i className="fa fa-tachometer"></i>
              <span>Painel Geral</span>
            </div>
          )}

          {dataPermissions?.user.user_type === 2 && (
            <div className="sidebar__link" onClick={() => areaClerk()}>
              <i className="fa fa-tachometer"></i>
              <span>Painel Atendente</span>
            </div>
          )}

          {dataPermissions?.user.user_type === 3 && (
            <div className="sidebar__link" onClick={() => areaInterprete()}>
              <i className="fa fa-tachometer"></i>
              <span>Painel Interprete</span>
            </div>
          )}

          {dataPermissions?.user.user_type === 4 && (
            <>
              <h2>CADASTROS</h2>
              <div className="sidebar__link">
                <i className="fa fa-user-circle"></i>
                <span onClick={() => usersRouter()}>Usuários</span>
              </div>
              <div className="sidebar__link">
                <i className="fa fa-user-circle"></i>
                <span onClick={() => TypeRouter()}>Tipo de Chamados</span>
              </div>
              {/* <div className="sidebar__link">
                <i class="fa fa-window-maximize"></i>
                <span onClick={() => navigate("/totems")}>Totems</span>
              </div> */}
            </>
          )}
          {dataPermissions?.user.user_type === 4 && (
            <>
              <h2>RELATÓRIOS</h2>
              <div className="sidebar__link">
                <i className="fa fa-user-circle"></i>
                <span>Atendimentos</span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="copy__right" style={{ color: "#FFF" }}>
        <div className="sidebar__logout" onClick={() => logoff()}>
          <i className="fa fa-power-off"></i>
          <span>
            <strong>Sair</strong>
          </span>
        </div>
        © Desenvolvido por Corps Teknologi {new Date().getFullYear()}
      </div>
    </div>
  );
};

export default Sidebar;
