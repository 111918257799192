import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Users from "../pages/Users";
import PainelClerk from "../pages/Painel/Clerk";
import PainelInterpreter from "../pages/Painel/Interpreter";
import ReportCallVideo from "../components/ReportCallVideo";
import NotFound from "../pages/NotFound";
import TypeCall from "../pages/TypeCall";
import CallEnd from "../pages/CallEnd";
const Routers = () => {
  return (
    <>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="" element={<Login />} />
        <Route path="/users" element={<Users />} />
        <Route path="/pclerk" element={<PainelClerk />} />
        <Route path="/report-video-call" element={<ReportCallVideo />} />
        <Route path="/pinterpreter" element={<PainelInterpreter />} />
        <Route path="/types" element={<TypeCall />} />
        <Route path="/callend" element={<CallEnd />} />
        {/* Case don't not works */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Routers;
