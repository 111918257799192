import "./index.css";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import Reports from "../../components/reports";
import api from "../../services/api";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react/button";
import { cpfMask } from "../../utils/mask";

const Users = () => {
  const [userName, setUserName] = useState(null);
  const [userList, setUserList] = useState(null);

  const [permissions, setPermissions] = useState();

  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const openSidebar = () => {
    setSidebarOpen(true);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const users = await api.get("/users");
    setUserList(users.data.filter((item) => item.active === 1));

    const storage = JSON.parse(localStorage.getItem("FLY@User"));
    setPermissions(storage);
    setUserName(storage.user.name);
  }, []);

  const handlerMaskCpf = (value) => {
    setInput3(cpfMask(value));
  };

  const handlerSubmit = async () => {
    let data = {
      name: input1,
      email: input2,
      password: input4,
      cpf: input3,
      user_type: input5,
      id_company: permissions.user.id_company,
    };

    if (input1 == "" || input2 == "" || input4 == "") {
      notify({
        message: "Atenção, campo não pode ficar vazio",
        type: "warning",
        delayTime: 1500,
        width: 300,
        shading: false,
        animation: {
          hide: { type: "fade", duration: 40, to: 0 },
          show: { type: "fade", duration: 400, to: 1, from: 0 },
        },
      });
      return;
    }
    const resp = await api.post("/users", data);

    if (resp.status === 201) {
      notify({
        message: "Cadastrado com Sucesso!",
        type: "success",
        delayTime: 1500,
        width: 300,
        shading: false,
      });
      window.location.reload();
    }
  };

  return (
    <div className="container">
      <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      <main>
        <div className="main__container">
          <div className="main__title">
            <div className="main__greeting">
              <h1>Cadastros</h1>
            </div>
          </div>
          <hr />
          <br />
          <div>
            <form className="form-user">
              <div className="user-wrapper">
                <div className="div-single">
                  <label>Nome</label>
                  <input
                    type="text"
                    name={input1}
                    id="input1"
                    placeholder="Nome"
                    onChange={(e) => setInput1(e.target.value)}
                  />
                </div>
                <div className="div-single">
                  <label>Email</label>
                  <input
                    type="text"
                    name={input2}
                    id="input2"
                    placeholder="Email"
                    onChange={(e) => setInput2(e.target.value)}
                  />
                </div>
                <div className="div-single">
                  <label>CPF</label>
                  <input
                    type="text"
                    name={input3}
                    id="input3"
                    value={input3}
                    placeholder="CPF"
                    onChange={(e) => handlerMaskCpf(e.target.value)}
                  />
                </div>
                <div className="div-single">
                  <label>Senha</label>
                  <input
                    type="text"
                    name={input4}
                    id="input4"
                    placeholder="Senha"
                    onChange={(e) => setInput4(e.target.value)}
                  />
                </div>
                <div className="div-single">
                  <label>Ativo</label>
                  <select name="ativo" id="ativo">
                    <option value="">.:Selecione um opção:.</option>
                    {/* <option value="">Toten</option> */}
                    <option value="">Ativo</option>
                    <option value="">Inativo</option>
                  </select>
                </div>
                <div className="div-single">
                  <label>Tipo de atendente</label>
                  <select
                    name="type"
                    id="input5"
                    onChange={(e) => setInput5(e.target.value)}
                  >
                    <option value="">.:Selecione um opção:.</option>
                    <option value="1">Toten</option>
                    <option value="2">Atendente</option>
                    <option value="3">Interprete</option>
                  </select>
                </div>
              </div>
              <br />
              <Button
                onClick={handlerSubmit}
                width={120}
                text="Salvar"
                type="success"
              />
            </form>
          </div>
          <br />
          <hr />
          <br />
          <div className="reports-area">
            <Reports
              size={10}
              edit={true}
              destroy={true}
              source={userList}
              endpoint="users"
            />
          </div>
        </div>
      </main>
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );
};

export default Users;
