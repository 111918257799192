import Routers from "./routes/routes";
import "./App.css";
import { ContextProvider } from "./Context";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <ContextProvider>
        <Routers />
      </ContextProvider>
    </BrowserRouter>
  );
};

export default App;
