import { useEffect, useState } from "react";

import Navbar from "../../components/navbar/Navbar";
import Main from "../../components/main/Main";
import Sidebar from "../../components/sidebar/Sidebar";
import Clerk from "../../components/Clerk";
import Interpreter from "../../components/Interpreter";

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    let arr = JSON.parse(localStorage.getItem("FLY@User"));
    console.log(arr);
    if (arr) {
      setPermissions(arr);
    }
  }, []);
  const openSidebar = () => {
    setSidebarOpen(true);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div className="container">
      <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      {permissions?.user.user_type === 4 && <Main />}
      {permissions?.user.user_type === 3 && <Interpreter />}
      {permissions?.user.user_type === 2 && <Clerk />}
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );
};

export default Dashboard;
