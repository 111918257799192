import React, { createContext, useState, useRef } from "react";

import { io } from "socket.io-client";

const SocketContext = createContext();

const socket = io("https://apiflytoten.corpstek.com.br");
// const socket = io("http://192.168.88.96:5555");

const ContextProvider = ({ children }) => {
  const [name, setName] = useState("");
  const [date, setDate] = useState({});

  return (
    <SocketContext.Provider
      value={{
        socket,
        name,
        setName,
        date,
        setDate,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
