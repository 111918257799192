import { useCallback, useContext, useEffect, useState } from "react";
import { ImCalendar } from "react-icons/im";
import { FiPhoneCall } from "react-icons/fi";
import { DataGrid } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { RiUserVoiceLine, RiTimer2Line } from "react-icons/ri";
import { Column, Paging, SearchPanel } from "devextreme-react/data-grid";

import { useNavigate } from "react-router-dom";
import "./index.css";
import Chart from "../charts/Chart";
import api from "../../services/api";
import { SocketContext } from "../../Context";

const Clerk = () => {

  const navigate = useNavigate();
  const { socket, data } = useContext(SocketContext);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [monthlyAppointments, setMothyAppointments] = useState(0);
  const [averageServiceTime, setAverageServiceTime] = useState(0.0);
  const [dataSourceGraph, setDataSourceGraph] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [haveCall, setHaveCall] = useState(false);
  const [resp, setResp] = useState(null);

  const [aceitouChamada, setAceitouChamada] = useState(0);

  const [userData, setUserData] = useState();

  const url = "./notification.ogg";
  const alert = new Audio(url);

  useEffect(() => {
    const arr = JSON.parse(localStorage.getItem("FLY@User"));
    setUserData(arr);
    api
      .get(`dashboard/card/interpreter/${arr.user.id}`)
      .then((response) => {
        setTotalAppointments(response.data.totalAppointments);
        setMothyAppointments(response.data.monthlyAppointments);
        setAverageServiceTime(
          `${parseFloat(
            response.data.averageServiceTime[0].avgDuration / 60
          ).toFixed(2)}/h`
        );
      })
      .catch((err) => {
        console.log(err);
        notify({
          message: "Problema com a conexão com o servidor",
          type: "error",
          displayTime: 3000,
        });
      });
    api
      .get(`dashboard/graph/interpreter/${arr.user.id}`)
      .then((response) => {
        setDataSourceGraph(response.data);
      })
      .catch((err) => {
        notify({
          message: `${err}`,
          type: "error",
          displayTime: 3000,
        });
      });
    api
      .get(`/dashboard/report/interpreter/${arr.user.id}`)
      .then((response) => {
        setDataSource(response.data[0]);
      })
      .catch((err) => {
        notify({
          message: `${err}`,
          type: "error",
          displayTime: 2000,
        });
      });
  }, []);

  socket.on("tem_chamada", async (resp) => {
    if (userData != null) {
      if (resp.room.id_interpreter === userData.interpreter.id) {
        setResp(resp);
        setHaveCall(true);
        playSong();
      }
    }
  });
  useEffect( () => {
    if(haveCall){
      playSong()
    }
  }, [haveCall])

  const playSong = () => {
    if(aceitouChamada === 0){
      alert.play();
    }
  };


  const callAccepted = () => {
    answeRCall()
  };
  const answeRCall = () => {
    if(setHaveCall){
      setHaveCall(false);
      setAceitouChamada(1)
      socket.emit("aceitou_chamada", {id : resp.room.id})
      document.cookie = `idi=${userData.interpreter.id}`;
      setTimeout(() => {
        navigate("/dashboard");
      }, 500);
      window.open(`https://flytoten.corpstek.com.br/join/${resp.room.id}`);
      // window.open(`http://localhost:5555/join/${resp.room.id}`);
    }
  };
  const convertToHors = (data) => {
    if (data.text) return `${data.text}h`;
  };
  return (
    <main>
      <div className="main__container">
        <div className="main__cards">
        <div
            onClick={callAccepted}
            className={`card  ${haveCall ? "card_green" : "card_call"}`}
          >
            <FiPhoneCall size="32" />
            <div className="card_inner">              
             {haveCall && (<span className="text-primary-p-call" style={{color:"#FFF"}}>Totem Chamando...</span>)}
            </div>
          </div>
          <div className="card">
            <RiUserVoiceLine size="32" />
            <div className="card_inner">
              <p className="text-primary-p">Total de atendimentos</p>
              <span className="font-bold text-title">{totalAppointments}</span>
            </div>
          </div>

          <div className="card">
            <ImCalendar size="32" />
            <div className="card_inner">
              <p className="text-primary-p">Atendimentos do mês</p>
              <span className="font-bold text-title">
                {monthlyAppointments}
              </span>
            </div>
          </div>

          <div className="card">
            <RiTimer2Line size="32" />
            <div className="card_inner">
              <p className="text-primary-p">Tempo médio de atendimento</p>
              <span className="font-bold text-title">{averageServiceTime}</span>
            </div>
          </div>
        </div>

        <div className="charts">
          <div className="charts__left">
            <div className="charts__left__title">
              <div>
                <h1>Gráfico Mensal</h1>
                <p>Natal, RN</p>
              </div>
            </div>
            <Chart dataSource={dataSourceGraph} />
          </div>

          <div className="charts__right">
            <div className="charts__right__title">
              <div>
                <h1>Relatório</h1>
                <p>Natal, RN</p>
              </div>
            </div>
            <DataGrid
              allowColumnReordering={true}
              dataSource={dataSource}
              key="id"
            >
              <Column
                dataField="id"
                caption={"ID"}
                dataType="number"
                alignment={"left"}
              />
              <Column
                alignment={"center"}
                dataField="duration"
                caption={"Duração"}
                dataType="number"
                cellRender={(data) => convertToHors(data)}
              ></Column>
              <Column
                dataField="clerk_name"
                caption={"Atendente"}
                dataType="string"
                alignment={"center"}
              />
              <Column
                dataField="name"
                caption={"Totem"}
                dataType="string"
                alignment={"center"}
              />
              <Column
                dataField="created_at"
                alignment={"right"}
                caption={"Data"}
                dataType="date"
              />
              <Column
                dataField="created_at"
                alignment={"center"}
                caption={"Inicio"}
                dataType="datetime"
              />
              <Column
                dataField="created_at"
                alignment={"center"}
                caption={"Fim"}
                dataType="datetime"
              />
              <SearchPanel visible={true} />
              <Paging defaultPageSize={10} defaultPageIndex={0} />
            </DataGrid>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Clerk;
